import { FieldPolicy, InMemoryCache, Reference } from '@apollo/client'

const createPaginatedField = (keyArgs: string[]): FieldPolicy => ({
  keyArgs,
  merge: (existing: Record<string, Reference> | undefined, incoming: Reference[], { cache }) => {
    const merged = { ...existing }

    incoming.forEach((item) => {
      const itemId = cache.identify(item)

      if (itemId) {
        merged[itemId] = item
      }
    })

    return merged
  },
  read: (existing: Record<string, Reference> | undefined) => {
    if (!existing) {
      return existing
    }

    return Object.values(existing)
  },
})

export const createInMemoryCache = () =>
  new InMemoryCache({
    typePolicies: {
      UploadUrl: {
        keyFields: false,
      },
      AgentDetail: {
        keyFields: ['agentId'],
      },
      ClientDetail: {
        keyFields: ['clientId'],
      },
      Query: {
        fields: {
          getAgents: createPaginatedField(['search', 'state']),
          getAgentsHasMore: {
            keyArgs: ['search', 'state'],
          },
          agentGetClients: createPaginatedField(['search', 'state']),
          agentGetClientsHasMore: {
            keyArgs: ['search', 'state'],
          },
          adminGetClients: createPaginatedField(['search', 'state']),
          adminGetClientsHasMore: {
            keyArgs: ['search', 'state'],
          },
          agentGetQuoteGroups: createPaginatedField(['search', 'state']),
          agentGetQuoteGroupsHasMore: {
            keyArgs: ['search', 'state'],
          },
          adminGetQuoteGroups: createPaginatedField(['search', 'state']),
          adminGetQuoteGroupsHasMore: {
            keyArgs: ['search', 'state'],
          },
          clientGetQuoteGroups: createPaginatedField(['search', 'state']),
          clientGetQuoteGroupsHasMore: {
            keyArgs: ['search', 'state'],
          },
        },
      },
    },
  })
