import { ApolloProvider } from '@apollo/client'
import { Amplify } from 'aws-amplify'
import { UserContextProvider } from 'context/common/UserContext'
import { useApollo } from 'hooks/useApollo'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Outfit } from 'next/font/google'
import { appWithTranslation } from 'next-i18next'
import { BaseAppProps } from 'types/common/next'
import { Toaster } from 'components/ui/toaster'
import outputs from '../../amplify_outputs.json'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '../../globals.css'

Amplify.configure(outputs, { ssr: true })

/*
 Uncomment for SSG
const getStaticProps = makeStaticProps(['common'])
export { getStaticPaths, getStaticProps }
 */

type AppPropsWithLayout = AppProps<BaseAppProps> & {
  Component: NextPage<BaseAppProps>
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  // useSetSentryUser()

  return (
    <UserContextProvider user={pageProps.user}>
      <ApolloProvider client={apolloClient}>
        <Component {...pageProps} />
      </ApolloProvider>
    </UserContextProvider>
  )
}

const AppWithI18n = appWithTranslation(App)

const outfit = Outfit({ subsets: ['latin'] })

const AppWithAuth = (props: AppPropsWithLayout) => (
  <main className={outfit.className}>
    <AppWithI18n {...props} />
    <Toaster />
  </main>
)

export default AppWithAuth
