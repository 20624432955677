import { createContext, ReactNode, useContext, useMemo } from 'react'
import { User } from 'types/common/user'

export type UserContextType = {
  user?: User | null
}

export const UserContext = createContext<UserContextType>({ user: null })

export const UserContextProvider = ({
  children,
  user,
}: {
  children: ReactNode
  user?: User | null
}) => (
  <UserContext.Provider value={useMemo(() => ({ user }), [user])}>{children}</UserContext.Provider>
)

export const useUser = () => useContext(UserContext)

export const useAuthenticatedUser = () => {
  const { user } = useUser()

  if (!user) {
    throw new Error(
      "useAuthenticatedUser must be used on pages which don't allow unauthenticated users",
    )
  }

  return user
}
